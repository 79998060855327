* {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}

body {
  background-color: rgb(194, 194, 194);
  height: 100vh;
}

#root {
  background-color: rgb(194, 194, 194);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.App {
  display: flex;
  padding: 30px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.06'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.header {
  background-color: aliceblue;
  position: fixed;
  padding: 5px;
  color: darkslateblue;
  font-size: 1.2rem;
  height: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: fit-content;
  margin-left: 6px;
  margin-bottom: 1px;
  font-style: oblique;
  font-size: 1.25rem;
}

.header-right {
  display: flex;
  align-items: center;
}

.loading-train {
  display: none;
  animation: pulse 1.25s infinite;
  margin-right: 2px;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.header-text {
  font-style: italic;
  font-size: 1rem;
  color: lightgray;
  font-family: "Courier New", Courier, monospace;
  margin-right: 12px;
}

h3 {
  margin-left: 5px;
}

.button-container {
  display: flex;
}

.subway-icon {
  transform: scale(2);
  flex-shrink: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  margin-top: 7px;
}

.title {
  background-color: gray;
  color: antiquewhite;
  margin: 25px;
  width: fit-content;
  font-size: 1.2rem;
  font-weight: 800;
}

.buttons {
  margin-left: 15px;
}

.button-wrapper {
  display: flex;
}

.big-train {
  transform: scale(0.5);
  margin-bottom: -150px;
  transition-duration: 0.2s;
}

.big-train:hover {
  transform: scale(0.52);
}

.landing-wrapper,
.login-wrapper,
.register-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  width: 100%;
  color: antiquewhite;
  padding: 25px;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.3'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  animation: fadeIn 0.5s linear;
  padding-bottom: 50px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  align-items: center;
}

form > * {
  margin-bottom: 5px;
  width: 100%;
}

label {
  font-size: 0.9rem;
  font-family: "Courier New", Courier, monospace;
}

input {
  height: 20px;
}
button {
  border-radius: 0;
  padding: 10px;
  background-color: rgb(231, 231, 231);
  /* border: 2px solid white; */
  color: rgb(63, 63, 63);
  height: 50px;
  width: 150px;
  border-radius: 30px;
  font-size: 0.85rem;
  font-weight: 600;
  margin: 10px;
  border: none;
}

button:hover {
  background-color: #fff2bd;
  cursor: pointer;
}

.monitor-box {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}
.add-wrapper {
  display: flex;
  justify-content: center;
  width: fit-content;
  /* flex-shrink: 2; */
  /* border: 5px solid black;
  background-color: gainsboro; */
  height: fit-content;
  align-items: center;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.299);

  /* border-radius: 10px; */
  /* border: 2px solid rgb(219, 219, 219); */
  margin-bottom: 20px;
}

.subway-icon-chooser {
  /* width: 100px; */
  transform: scale(2.5);
}

.station-chooser-wrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-left: 80px;
  padding-right: 80px;
}

.station-chooser {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dropdown-title {
  content: "Select a station";
  color: rgb(112, 98, 81);
  font-family: "Helvetica";
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: -5px;
  /* font-weight: 800; */
}
.station-chooser > .Dropdown-root {
  width: 300px;
  text-align: left;

  /* margin-top: 5px; */
}

.Dropdown-menu {
  max-height: 400px;
}

.service-chooser-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
}

.service-chooser {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
}

.station-chooser > .subway-icon {
  transform: scale(3);
}

.service-chooser-bottom {
  /* width:  */

  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  /* padding: 0; */
}

.service-chooser-bottom > button {
  width: fit-content;
  /* padding: 10px; */
  border-radius: 3px;
  height: fit-content;
  padding: 8px;
  /* margin-top: 15px; */
  margin: 0;
  margin-bottom: 6px;
  margin-left: 13px;
  margin-top: 11px;
  /* margin-left: 20px; */
}

.subway-icon-service {
  font-family: Arial, Helvetica, sans-serif;
  transform: scale(1.5);
}

.other-services {
  display: flex;
  gap: 15px;
  list-style-type: none;
  width: fit-content;
  height: 40px;
  margin-top: 15px;
  justify-content: flex-end;
}

.other-line {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  /* align-items: center; */
  /* flex-direction: column; */
}

.other-line > input {
  margin-top: 3px;
  transform: scale(1.2);
}

.monitor-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: rgb(48, 48, 65);
  font-weight: 800;
  border-radius: 10px;
  /* background-color: white; */
  width: 350px;
  /* max-height: 615px; */
  transition-duration: 0.3s;
  align-items: center;
  animation: fadeIn 0.5s linear;
}

@keyframes grow {
  0% {
    height: 0px;
    opacity: 0%;
  }
  100% {
    height: 275px;
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.monitor-header {
  display: flex;
  /* gap: 15px; */
  background-color: rgb(20, 20, 20);
  /* box-sizing: border-box; */
  /* border: 5px solid black; */
  color: white;
  width: 100%;
  /* padding: 10px; */
  /* padding-left: 20px; */
  padding-bottom: 5px;
  justify-content: space-between;
  /* height: 65px; */
  /* max-height: 65px; */
  align-items: center;
}

.monitor-header-lines {
  display: flex;
  gap: 20px;
  /* margin-bottom: 15px; */
  /* background-color: rgb(48, 48, 48); */
  padding: 10px;
  padding-bottom: 12px;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  /* width: fit-content; */
  /* justify-content: space-between; */
}

.header-icon-wrapper::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 14px;
  margin-top: 28px;
  margin-left: 4px;
  background-color: rgb(52, 52, 52);
  /* border-right: 2px solid black; */
  /* opacity:  */
}

.header-icon-wrapper {
  transform: scale(0.75);
}

.monitor-header > div {
  margin-left: 10px;
}

.station-name {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 5px;
}

.station-name-small {
  text-align: center;
  font-size: 1.25rem;
  margin-top: 8px;
  word-wrap: none;
}

.station-name-xtra-small {
  font-size: 1rem;
  margin-top: 5px;
}

.remove-monitor-btn {
  cursor: pointer;
  color: antiquewhite;
  /* background: #cdcbd0; */
  font-size: 12px;
  height: 13px;
  width: 25px;
  margin-top: 3px;
  margin-left: 16%;
  /* border: 1px solid white; */
  /* padding: 2px; */
  border-radius: 50%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-family: "Courier New", Courier, monospace;
  transition-duration: 0.5s;
}

.remove-monitor-btn:hover {
  color: rgb(111, 111, 111);
}

.monitor-trains {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* border-radius: 5px; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.train-wrapper,
.train-wrapper-filtered {
  display: flex;
  /* justify-content: flex-start; */
  /* transform: scale(0.75); */
  gap: 15px;
  border-top: 1px solid rgba(230, 230, 230, 0.5);
  border-bottom: 1px solid rgba(230, 230, 230, 0.5);
  padding: 10px;
  width: 100%;
  animation: fadeIn 1s linear;
  transition-duration: 0.35s;
  height: 62;
  max-height: 62px;
  /* max-height: 35px; */
  align-items: center;
}

.train-wrapper > .subway-icon,
.train-wrapper-filtered > .subway-icon {
  transform: scale(1.5);
  margin-left: 16px;
  margin-bottom: 11px;
}

.train-wrapper-filtered {
  opacity: 25%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

/* .train-wrapper-filtered + .train-wrapper {
  border-top: 1px solid rgba(230, 230, 230, 0.5);
} */

.headsign {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.headsign-small {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.timeToStop {
  font-style: italic;
  font-size: 0.8rem;
  color: gray;
  font-weight: 400;
}

.delay {
  font-size: 0.8rem;
}

.placeholder {
  font-style: italic;
  color: gray;
  width: 75%;
  text-align: center;
  height: 70px;
  max-height: 70px;
  margin: auto;
  float: top;
  padding-top: 25px;
}

.add-lines {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 600px;
  justify-content: center;

  border-radius: 5px;
  padding: 40px;
}

.add-lines > .subway-icon::before {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.headsign {
  font-weight: 800;
}

.train-direction {
  font-size: 0.6rem;
  background-color: rgb(253, 245, 216);
  padding: 3px;
  color: gray;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  margin-left: 5px;
  vertical-align: middle;
  /* margin-bottom: 1px; */
}

.time {
  font-style: italic;
}

.placeholder > * {
  font-style: normal;
  transform: scale(1);
}

.no-monitors {
  font-size: 1.5rem;
  color: gray;
  border: 2px solid #fccc0a;
  background-color: white;
  font-family: "Courier New", Courier, monospace;
  width: fit-content;
  padding: 10px;
  text-align: center;
  box-shadow: -5px 5px red;
  font-weight: 800;
}

@keyframes fadeOut {
  0% {
    display: flex;
    opacity: 100%;
  }
  99% {
    display: flex;
    opacity: 25%;
  }
  100% {
    opacity: 0%;
    display: none;
  }
}

.twitter-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  align-self: center;
  justify-self: flex-end;
  margin-top: 200px;
  animation: fadeIn 2s linear;
  z-index: 5;
  width: 500px;
}

.twitter-wrapper > * {
  display: flex;
  justify-content: center;
  border-radius: 0;
  margin-top: 30px;
  animation: fadeIn 2s linear;
}

.twitter-timeline-rendered {
  border-radius: 0;
  animation: fadeIn 1s linear;
  /* transform: scale(0.75); */
  /* transform: scale; */
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: antiquewhite;
  font-style: oblique;
  position: absolute;
  z-index: -1;
  left: auto;
  bottom: auto;
}

.loading-text {
  font-family: "Courier New", Courier, monospace;
}

.footer-wrapper {
  align-self: flex-start;
  color: gainsboro;
  font-style: oblique;
  padding: 2px;
}
.footer,
.footer > * {
  font-family: "Courier New", Courier, monospace;
}

@media only screen and (max-width: 600px) {
  #root,
  .App,
  .App > * {
    width: 100%;
  }

  .App {
    padding: 0;
    /* padding-right: 10px; */
    padding-top: 35px;
    overflow-x: hidden;
    flex-wrap: none;
    /* width: 50%; */
    /* gap: 2px; */
    /* gap: 5px; */
  }

  .header {
    width: 100%;
    margin: 0;
    position: relative;
    /* margin-bottom: 10px; */
  }

  .landing-wrapper {
    padding-bottom: 170px;
  }

  .login-wrapper,
  .register-wrapper {
    padding-bottom: 115px;
  }

  .big-train {
    transform: scale(0.4);
  }

  .register-wrapper > form {
    align-items: center;
  }

  .register-wrapper > form > button {
    transform: scale(0.8);
  }

  .header-text {
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
    /* font-weight: 800; */
  }

  .title {
    width: fit-content;
    padding: 2px;
    margin: 0;
    /* margin-bottom: 8px; */
    background-color: gray;
    font-size: 0.8rem;
    /* font-family: 'Courier New', Courier, monospace; */
  }

  .button-container {
    justify-content: center;
    margin-top: 8px;
    gap: 0;
    margin-right: 5px;
    /* background-color: rgb(189, 189, 189); */
    /* margin-right: 10px; */
  }

  .button-container > button {
    padding: 0;
    /* width: 50px; */
    transform: scale(0.9);
    box-sizing: border-box;
  }
  .add-wrapper,
  .lineChooser,
  .add-lines {
    /* width: 85%; */
    padding: 0;
  }

  .add-wrapper {
    padding: 5px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 15px;
  }

  .lineChooser {
    display: flex;
    padding: 20px;
    height: fit-content;

    /* animation: fadeIn 0.1s linear; */
  }

  .other-services {
    display: flex;
    flex-wrap: wrap;
    /* width: 150px; */
    align-items: center;
    margin-bottom: 12px;
    gap: 15px;
  }

  .other-line > input {
    margin-top: 7px;
    height: 15px;
    width: 15px;
  }

  .other-line > label {
    margin-right: 10px;
  }

  .monitor-box {
    justify-content: flex-start;
    align-items: center;
    gap: 55px;
    height: 100%;
    width: 100%;
    /* width: 50px; */
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom: 100px;
  }

  .monitor-wrapper {
    margin-bottom: -20px;
    width: 80%;
  }

  .monitor-trains {
    padding: 0;
  }

  .train-wrapper,
  .train-wrapper-filtered {
    height: 62px;
    max-height: 62px;
  }

  .add-lines {
    gap: 25px;
    width: 100%;
  }

  .add-lines > .subway-icon {
    transform: scale(1);
    width: 40px;
    height: 40px;
    font-size: 2.5rem;
  }

  .station-chooser {
    display: flex;
    flex-direction: column;
  }
  .service-chooser-wrapper {
    justify-content: center;
    align-items: center;
  }
  .service-chooser {
    align-items: center;
    justify-content: center;
  }
  input {
    font-size: 16px;
  }
  .train-direction {
    font-size: 0.5rem;
  }
}
